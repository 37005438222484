import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className='navbar'>
            <div className="navbar-toggle" onClick={toggleMenu}>
                ☰ {/* Icono de hamburguesa */}
            </div>
            <ul className={`nav-list ${isOpen ? 'active' : ''}`}>
                <li className="nav-item"><Link to="/">Inicio</Link></li>
                <li className="nav-item"><Link to="/Services">Servicios</Link></li>
                <li className="nav-item"><Link to="/About">Sobre Nosotros</Link></li>
                <li className="nav-item"><Link to="/Contact">Contáctanos</Link></li>                
            </ul>
        </nav>
    );
};

export default Navbar;
