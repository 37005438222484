import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pkni7km', 'template_9xqfror', form.current, 'ERatGBByhmnGSIRIa')
      .then((result) => {
          console.log(result.text);
          alert('¡Mensaje enviado exitosamente!');
          form.current.reset();
      }, (error) => {
          console.log(error.text);
          alert('Hubo un error al enviar el mensaje, por favor intenta nuevamente.');
      });
  };

  return (
    <div>
      <header id="hero">
        <h2>¿Tienes alguna duda o idea? ¡Estamos aquí para escucharte!</h2>
        <p>
          Ya sea que necesites más información, quieras colaborar con nosotros o
          simplemente decir "hola", nos encantaría saber de ti.
        </p>
        <p>
          Completa el formulario a continuación y nos pondremos en
          contacto contigo lo más pronto posible.
        </p>
        <p>¡No dudes en escribirnos, estamos para ayudarte en cada paso del camino!</p>
      </header>

      <div className="contact-container">
      <form ref={form} onSubmit={sendEmail}>
            <div>
              <label>Nombre(s)</label>
              <input type="text" name="user_name" required placeholder="Ingresa tu nombre" />
            </div>

            <div>
              <label>Apellido(s)</label>
              <input type="text" name="last_name" required placeholder="Ingresa tu apellido"/>
            </div>

            <div>
              <label>Correo Electrónico</label>
              <input type="email" name="user_email" required placeholder="Ingresa tu correo electrónico"/>
            </div>

            <div>
                <label>Número de Teléfono</label>
                <div className="tel-container">
                    <select name="country_code" required>
                    <option value='+52'>MX (+52)</option> {/* México */}
                    <option value='+1'>US (+1)</option> {/* Estados Unidos */}
                    <option value='+55'>BR (+55)</option> {/* Brazil */}
                    <option value='+1'>CA (+1)</option>  {/* Canadá */}
                    <option value='+44'>UK (+44)</option> {/* Reino Unido */}
                    <option value='+34'>ES (+34)</option> {/* España */}
                    <option value='+33'>FR (+33)</option> {/* Francia */}
                    </select>
                    <input 
                        type='tel' 
                        name='user_tel' 
                        required 
                        placeholder="Ingresa tu número de teléfono" 
                        pattern="[0-9]*" 
                        inputMode="numeric" 
                    />
                </div>
            </div>

            <div>
              <label>Asunto</label>
              <input type="text" name="subject" required placeholder="Ingresa tu asunto"/>
            </div>

            <div>
              <label>Mensaje</label>
              <textarea name="mensaje" required placeholder="Ingresa tu mensaje"/>
            </div>

            <div>
              <button type="submit">Enviar</button>
            </div>
          </form>
      </div>
    </div>
  );
}
export default Contact;
