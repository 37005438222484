import React from 'react';
import './Footer.css';

function Footer(){
    return(
        <footer>
            <p>&copy; 2024 JL CodeDev - Todos los derechos reservados.</p>
            <a href='mailto:contacto@jlcodedev.com' className='correo'>contacto@jlcodedev.com</a>
        </footer>
    )
};

export default Footer;