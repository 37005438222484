import React from 'react';

function About() {
    return (
        <div>
            <h2>Sobre Nosotros</h2>
            <p>En JL CodeDev ofrecemos soluciones de desarrollo personalizadas para satisfacer las necesidades de nuestros clientes.</p>
        </div>
    );
}

export default About;