import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importa BrowserRouter, Route y Routes
import './App.css';
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Services from './components/Services';
import About from './components/About.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';


function App() {
  return (
    <Router>
      <div>
        <Navbar /> {/*Menú de la página*/}
        <Routes>
          <Route path="/" element={<Home />} /> {/*Home o Inicio*/}
          <Route path="/services" element={<Services />} /> {/*Services o Servicios*/}
          <Route path="/about" element={<About />} /> {/*Aboutmo Sobre Nosotros*/}
          <Route path="/contact" element={<Contact />} /> {/*Contact o Contacto*/}
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

function Home() {
  return (
    <div>
      <header id='hero'>
        <h1>Bienvenido a JL CodeDev</h1>
        <p>Ofrecemos soluciones personalizadas para tu negocio. ¿Listo para llevar tu proyecto al siguiente nivel?</p>
        <Link to='/contact' className='btn'>Contáctanos</Link>
      </header>
    </div>
  );
}


export default App;